<template>
 <div :class="windowClass">
    
      <v-row no-gutters>
        <v-col cols="12">
          <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
        <v-card style="background: none;" class="pa-2 elevation-0">
             
              <v-card-text>
                <div v-if="voter.id>0"  style=" padding: 10px; border: 1px solid #ddd;">
                      
                      <div >
                        <span class="text-h6">{{voter.fullname}}</span>
                        <br/>
                        PRECINCT #:  {{voter.prec}}
                        <!-- <br/>
                       
                        STATUS: {{voter.status}} -->
                       </div> 
                       <v-divider class="mt-4 mb-4"/>
                       <div v-if="event.id != 0">
                        <span class="text-h6">Event: {{event.name}}</span>
                        <br/>
                        Date: {{ $moment(event.event_dt).format('MMM DD, YYYY') }}
                        <br/>
                       
                        Status: {{event.status}}
                       </div> 

                       <v-divider class="mt-4 mb-4"/>
                       <v-btn
                       class="mt-2 mb-4"
                       v-if="event.id != 0 && claim_status==''"
                        color="success"
                        block
                        rounded
                        large
                        :loading="loading"
                        @click="confirm=true"
                      >
                        CLAIM
                      </v-btn>
                      <v-btn
                       class="mt-2 mb-4"
                       v-else
                        color="error"
                        text
                        block
                        rounded
                        large
                      >
                        Already CLAIMED
                      </v-btn>
                      <v-btn
                      class="mt-2"
                        color="warning"
                        block
                        rounded
                        large
                        @click="voter={id:0}, code=''"
                      >
                        Back
                      </v-btn>
                    </div>
                <div v-else>
                      <v-text-field
                      
                        v-model="code"
                        :rules="nameRules"
                        label="Enter voter`s name"
                        required
                        outlined
                      ></v-text-field>
                      <div>
                        <template v-for="(item, index) in filteredVoters">
                        <v-list-item two-line :key="index">
                          <v-list-item-content @click="selectVoter(item)">
                            <v-list-item-title>{{ item.fullname }}</v-list-item-title>
                            <v-list-item-subtitle>Prec #: {{ item.prec }} </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      </div>
                      <!-- <v-btn
                        color="primary"
                        block
                        rounded
                        large
                        :loading="loading"
                        @click="validate()"
                      >
                        PROCEED
                      </v-btn> -->
                    </div>
                  
              </v-card-text>
           
            </v-card>
            </v-form>
          </v-col>
      </v-row>
      <va-confirm :show="confirm" okbtn="Claim Now!" cancelbtn="Not Now" message="Claim confirm?" @ConfirmEvent="conEv"/>
  </div>
</template>
<script>
import { API_MIXINS } from '@/mixins/api_mixins.js'
// import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
import {
  mapMutations
} from 'vuex'
  export default {
    mixins: [API_MIXINS],
    components: {
    //   QrcodeStream,
    // QrcodeDropZone,
    // QrcodeCapture
  },
    data: () => ({
      confirm: false,
      valid: true,
      code: '',
      loading: false,
      event:{id:0},
      voter: {},
      claim_status:"",
      voters: [],
      nameRules: [
        v => !!v || 'Field is required'
      ],
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
     
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
         this.getUsers()
      }      
    },
    beforeDestroy(){
      //  if(this.inter_1 != null) { clearInterval(this.inter_1)}
       return true
    },
    computed:{ 
      user() {
        if(sessionStorage.user){
            return JSON.parse(sessionStorage.user)
        }
        return null
      },
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
     filteredVoters(){
      var items = []
        if(this.code !=''){
          if(this.code.length>2) {
            items = this.voters.reduce((res,item)=>{
              let fullname = item.fullname
              let index = fullname.search(new RegExp(this.code, 'i'));
                if(index != -1) {
                res.push(item)
              }
              return res
            },[]) 
          }
        } 
        return items
     }
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn', 'setOpenDraws']),
     
  conEv(e) {
    this.confirm = false
    if(e=='ok') {
      this.claim()
    }
  },
  claim() {
    this.loading = true
    this.$http.post("voter/claim",{ voter_id: this.voter.id}).then(response => {
      this.loading = false
              response.data.status?this.VA_ALERT('success',  response.data.message):this.VA_ALERT('error',  response.data.message)
              if( response.data.status) {
                setTimeout(()=>{
                
                  this.getUsers()
                  this.voter = {id:0}
                this.code = ''
                }, 500)
               
              }
          }).catch(e => {
            console.log(e)
          })
  },
  validate () {
        if (this.$refs.form.validate()) {
          this.getVoter()
        }
      },
  selectVoter(selected){
    this.voter=selected
    this.getEvent()
  },
  getEvent(){
    this.$http.post("event/query",{status:"Ongoing", id: this.voter.id}).then(response => {
      response.data.event !=null?this.event = response.data.event:this.event ={id:0}
      response.data.claim_status !=null?this.claim_status = response.data.claim_status:this.claim_status =''
          }).catch(e => {
            console.log(e.data)
          })
  },
  getVoter() {
    this.$http.post("voter/view",{status:"by_voucher", id: this.voter.id}).then(response => {
               response.data.record !=null?this.voter = response.data.record:this.voter = {}
                console.log(response.data)
                this.loading = false
          }).catch(e => {
            console.log(e.data)
          })
  },
  getUsers: function() { 
        this.$http.get("voter/list").then(response => {
          console.log(response.data)
            response.data.list != null?this.voters = response.data.list:this.voters =[]
        }).catch(e => {
        console.log(e.data)
        })
     },
    }
  }
</script>